import { menuData } from '@/data/menu';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import MenuDropdown from './list/MenuDropdown';

interface MenuProps {
  menuType?: string;
  notFixedHeader?: boolean;
  absolute?: boolean;

}

const Menu = ({ menuType, notFixedHeader, absolute }: MenuProps) => {
  const [navbar, setNavbar] = useState(false);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', changeBgOnScroll, true);
    return () => window.removeEventListener('scroll', changeBgOnScroll);
  }, []);

  const changeBgOnScroll = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  return (
    <>
      <Navbar
        id='menu'
        bg={navbar ? 'light' : menuType}
        expand='lg'
        fixed={notFixedHeader ? undefined : 'top'}
        className={`${absolute? 'position-absolute position-absolute-ll':''} ${navbar ? 'fixed-border' : menuType === 'transparent' ? 'navbar-transparent' : ''} `}
        // className={navbar ? 'fixed-border' : ''}
        expanded={expanded}
      >
        <Container fluid className={`${absolute? 'position-absolutes-color menu-container' :'menu-container'}`}>
          <Navbar.Brand href='/'>
            <Image
              src={menuType === 'transparent' && !navbar ? '/assets/logo/cubet-white.png' : '/assets/logo/cubet-logo-with-text.svg'}
              width={295}
              height={74}
              priority={true}
              alt='Cubet'
              className='logo'
              style={{ objectFit: 'contain' }}
            />
          </Navbar.Brand>
          <div className='d-flex align-items-center'>
            {menuType === 'transparent' ? "" : <Link prefetch={false} href='/contact-us' className='d-lg-none d-block me-3'>
              <Image
                src='/assets/icons/mail.svg'
                width={24}
                height={24}
                priority={true}
                alt='Cubet'
                className='contact-email'
                style={{ objectFit: 'contain' }}
              />
            </Link>}
            <Navbar.Toggle aria-controls='navbarScroll' onClick={() => { setExpanded(!expanded) }} className={menuType === 'transparent' && !navbar ? 'navbar-white' : ''} />
          </div>
          <Navbar.Collapse id='navbarScroll'>
            <Nav className={menuType === 'transparent' && !navbar ? ' me-auto mr-auto my-2 my-lg-0 bg-white-pl' : 'me-auto mr-auto my-2 my-lg-0'} navbarScroll>
              {menuData.map((item: any, index: number) => (
                // <MenuDropdown data={item} key={index.toString()} menuType={menuType} />
                <MenuDropdown data={item} key={index.toString()} expanded={expanded} setExpanded={setExpanded} navbar={navbar} menuType={menuType} />
              ))}
              <Link prefetch={false} href='/industries' className={menuType === 'transparent' && !navbar ? 'header-color-white nav-link' : 'nav-link'}>
                Industries
              </Link>
              <Link prefetch={false} href='/about-us' className={menuType === 'transparent' && !navbar ? 'header-color-white nav-link' : 'nav-link'}>
                Who we are
              </Link>
              <Link prefetch={false} href='/resources/blog' className={menuType === 'transparent' && !navbar ? 'header-color-white nav-link' : 'nav-link'}>
                Insights
              </Link>
              <Link prefetch={false} href='/cubet-career' className={menuType === 'transparent' && !navbar ? 'header-color-white nav-link' : 'nav-link'}>
                Careers
              </Link>
            </Nav>
            <Link prefetch={false}
              href='/refer-us'
              className={`connect me-3 text-center mb-2 mb-lg-0 d-none d-lg-inline ${menuType === 'transparent' && !navbar ? 'landing-connect':''}`}
            >
              {/* <span> {menuType === 'transparent' ? 'Connect Now' : 'Contact Us'}</span> */}
              <span> {menuType === 'transparent' ? 'Refer Us' : 'Refer Us'}</span>

            </Link>
            <Link prefetch={false}
              href='/contact-us'
              className={`connect text-center mb-2 mb-lg-0 d-none d-lg-inline ${menuType === 'transparent' && !navbar ? 'landing-connect':''}`}
            >
              {/* <span> {menuType === 'transparent' ? 'Connect Now' : 'Contact Us'}</span> */}
              <span> {menuType === 'transparent' ? 'Contact Us' : 'Contact Us'}</span>

            </Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className={notFixedHeader ? '' : 'menu-top-height'}></div>
    </>
  );
};

export default Menu;
